<template>
    <div>

        <div class="jumbotron jumbotron-fluid title__secondary">
            <div class="container max-width-1000px">
                <h1 class="display-4 title-center">Browserextensie</h1>
            </div>
        </div>

        <div class="bg--white">
            <div class="container">

                <div class="space--50"></div>

                <div class="row">
                    <div class="col-md-7">
                        <h3>Blijf continu beschermd tegen phishingaanvallen</h3>
                        <p>
                            We hebben checkjelinkje.nl gemaakt om phishing <b>nu</b> te stoppen. Sinds
                            we de
                            website hebben geïntroduceerd wordt 'ie al goed gebruikt en zijn we inmiddels
                            <a href="https://www.consumentenbond.nl/veilig-internetten/phishing-via-sms#no6"
                               target="_blank">aanbevolen
                                door de Consumentenbond.</a> Een mooi begin.
                        </p>
                        <p>
                            We hebben echter het vermoeden dat je niet elk linkje dat je gaat bezoeken bij ons checkt.
                            Zo
                            kun je ongemerkt toch nog op een phishingsite terechtkomen, iets dat we juist willen
                            voorkomen.
                            Daarom hebben we ook een browserextensie gemaakt. Deze kijkt continu of je pagina's probeert
                            te
                            bezoeken die wellicht phishing bevatten, als dat gebeurt krijg je een waarschuwing.
                        </p>
                        <p>
                            Daarnaast kun je met de extensie linkjes controleren door er rechts op te klikken. Erg
                            handig
                            als je bijvoorbeeld snel wilt weten waar een kort adres (zoals "bit.ly") naartoe gaat.
                        </p>
                        <p>
                            We begrijpen dat je wellicht vragen hebt over hoe we met je privacy omgaan. Je wilt
                            waarschijnlijk niet dat we je gehele browsergeschiedenis vastleggen, geen zorgen!
                        </p>

                        <br><br>

                        <h4>Je privacy met de Checkjelinkje browserextensie</h4>
                        <p>
                            Laten we beginnen met te zeggen dat we je browsergeschiedenis <b>niet</b> opslaan. Sterker
                            nog,
                            we
                            ontvangen je browsergeschiedenis niet eens. Maar, hoe controleren we dan of een site
                            phishing
                            bevat? Daar hebben we wat op bedacht.
                        </p>
                        <p>
                            Zoals je misschien al weet houden we een lijst bij van sites waarvan we weten dat ze
                            phishing
                            bevatten. Onze browserextensie downloadt automatisch elk half uur in de achtergrond een
                            kopietje
                            van die lijst. Vervolgens controleert 'ie lokaal (dus op je apparaat) of de site die je gaat
                            bezoeken in het lijstje voorkomt. Als dat zo is, krijg je een waarschuwing.
                        </p>
                        <p>
                            De check wordt dus niet op onze servers uitgevoerd, maar gewoon bij jou op je apparaat. Zo
                            krijgen we dus helemaal niet te zien welke pagina's je checkt of bezoekt.
                        </p>
                        <p>
                            Waarom vereist de browserextensie dan toch toegang tot m'n browsegeschiedenis? Dat hebben we
                            nodig om die check lokaal te doen. We beloven dat we je browsegeschiedenis niet bijhouden.
                            En
                            als je ons niet op onze blauwe ogen gelooft, kun je de broncode van de extensie bekijken om
                            het
                            te controleren.
                        </p>

                        <br><br>

                        <h4>Een waarschuwing</h4>
                        <p>Zo ziet een waarschuwing van onze extensie er uit.</p>
                        <video
                                ref="video"
                                playsinline
                                autoplay
                                muted
                                src="https://vid--global-brlu.freavehd.net/stream/checkyourlink/nl/browser-warning/chrome-warning.mp4"
                                type="video/mp4"
                                poster="/img/cjl-warning.png"
                                onload="this.play();"
                        ></video>
                        <div class="space space--10"></div>
                        <p class="text-center" id="replay-container">
                            <a href="javascript:void(0)" id="replay" v-on:click="replay"><i class="far fa-undo"></i>
                                Vanaf het begin</a>
                        </p>

                        <br><br><br>

                        <h4>Downloaden</h4>
                        <p>Je kunt de extensie downloaden voor Firefox en Chrome. <br><br>
                            <a href="https://chrome.google.com/webstore/detail/checkjelinkjenl-voor-je-b/emhmcmaagaihdokaiccmmidnfndeilil"
                               target="_blank">Download voor <i
                                    class="fab fa-chrome"></i> Chrome</a><br>
                            <a href="https://addons.mozilla.org/nl/firefox/addon/checkjelinkje-nl-in-je-browser/"
                               target="_blank">Download voor <i
                                    class="fab fa-firefox"></i> Firefox</a>
                        </p>

                        <br><br>

                        <h3>Vragen/feedback</h3>
                        <p>Heb je vragen of feedback over de extensie? Laat het ons weten op <br>
                            <a href="mailto:support@checkjelinkje.nl">support@checkjelinkje.nl</a>.</p>
                    </div>

                    <div class="col-md-1"></div>
                    <div class="col-md-4">

                        <div class="position-sticky">
                            <h3 class="title">Download de extensie</h3>
                            <p>
                                Linkjes checken zonder handmatig onze website te bezoeken? Gebruik onze handige
                                browserextensies.
                                <br><br>
                                <a href="https://chrome.google.com/webstore/detail/checkjelinkje-voor-je-bro/emhmcmaagaihdokaiccmmidnfndeilil" target="_blank">Download voor <i
                                        class="fab fa-chrome"></i> Chrome <i class="far fa-arrow-right"></i></a><br>
                                <a href="https://addons.mozilla.org/nl/firefox/addon/checkjelinkje-nl-in-je-browser/" target="_blank">Download voor <i
                                        class="fab fa-firefox"></i> Firefox <i class="far fa-arrow-right"></i></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <br><br><br>
        </div>
    </div>
</template>
<style scoped>
    #replay {
        text-decoration: none;
    }

    video {
        max-width: 100%;
        height: auto;
        box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15)
    }
</style>
<script>
  import MicroModal from "micromodal";
  import "whatwg-fetch";

  export default {
    name: "Browser",
    data: function () {
      return {};
    },
    methods: {
      replay: function () {
        let video = this.$refs.video;
        video.currentTime = 0;
        video.play();
      },
    },
  };
</script>
